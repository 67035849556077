import { TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link"  

const Footer = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const navigate=useNavigate()
  return (
    <footer className="ftr pt_80">
      <div className="container">
        <div className="footer_flx">
          <div className="footer_cnt_bx">
            <figure className="logo">
              <img src="/static/images/logo.png" alt="" />
            </figure>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </div>
          <div className="footer_cnt_bx">
            <h4>Quick Links</h4>
            <ul className=" mn_ftr">
              <li>
                <a>Home</a>
              </li>
              <li>
                <HashLink to="/#about-us">About Us</HashLink>
               
              
              </li>
              <li>
              <HashLink to="/#faq">FAQ's</HashLink>
              </li>
              <li>
              <a href="/privacy-policy">Privacy Policy</a>
              </li>
            </ul>
          </div>

          <div className="footer_cnt_bx">
            <h4>Our Services</h4>
            <ul className=" mn_ftr">
              <li onClick={()=>navigate("/cars")}>
                <a>Cars</a>
              
              </li>
              <li>
               <HashLink to="/#calculate-loan">Calculate your loan</HashLink>
                {/* <a>Calculate your loan</a> */}
              </li>
            </ul>
          </div>
          <div className="footer_cnt_bx">
          <h4>Subscribe</h4>
     
                <div className="subscribe_ftr sub_btn">
                 
              
                    <TextField
                      // value={value}
                      // onChange={onChange}
                      className="ftr_txtfld"
                      hiddenLabel
                      placeholder="Enter your email"
                      fullWidth
                      variant="outlined"
                    />
                    <button>Subscribe</button>
                  </div>
              
                <div className="scl_icns">
                <Tooltip title="Facebook">
                  <figure>
                    <img src="/static/images/facebook.png" />
                  </figure>
                  </Tooltip>
                  <Tooltip title="Instagram">
                  <figure>
                    <img src="/static/images/instagram.png" />
                  </figure>
                  </Tooltip>
                  <Tooltip title="LinkedIn">
                  <figure>
                    <img src="/static/images/linkedin.png" />
                  </figure>
                  </Tooltip>
                  <Tooltip title="Youtube">
                  <figure>
                    <img src="/static/images/youtube.png" />
                  </figure>
                  </Tooltip>
                </div>
           
          </div>
        </div>
        <div className="ftr_btm">
          <p>© 2024 QFI, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
