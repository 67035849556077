import { Button } from "@mui/material";
import React from "react";

const CalculateLoan = () => {
  return (
    <section className="calculate_loan_sc al_sc" id="calculate-loan">
      <div className="container ">
        <div className="mn_calc_loan d_flex">
          <div className="calc_lft">
            <figure>
              <img src="/static/images/calculate_loan.png" alt="" />
            </figure>
          </div>
          <div className="calc_ryt fs_48">
            <h2>Calculate your loan</h2>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout
            </p>
            {/* <Button className="btn btn_black">Calculate</Button> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CalculateLoan;
