import { Tooltip } from "@mui/material";
import React from "react";

const DownloadApp = () => {
  return (
    <section className="dwnld_bnr al_sc " id="downlaod">
      <div className="mn_bnr_dwnld container">
        <div className="dwnld_bnr_lft fs_48">
          <h2>Download the link for the app</h2>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout
          </p>
          <div className="plystr_btns">
            <Tooltip title="Coming Soon">
              <figure>
                <img src="/static/images/play_store.png" alt="" />
              </figure>
            </Tooltip>
            <Tooltip title="Coming Soon">
            <figure>
              <img src="/static/images/app_store.png" alt="" />
            </figure>
            </Tooltip>
          </div>
        </div>
        <div className="dwnld_bnr_ryt">
          <figure>
            <img src="/static/images/bnr_phone.png" alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;
