import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const Header = () => {
  const [show, setShow] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header className={scrolling ? "scrolled hdr" : "hdr"} onClick={() => setShow(!show)}>
      <div className="hdr_mn container">
        <div className="hdr_lft">
          <figure>
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </div>
        <ul className={show ? "actv hdr_ryt" : "hdr_ryt"}>
          <li>
            <HashLink to="/#">Home</HashLink>
          </li>
          <li>
            {" "}
            <HashLink smooth to="/#about-us">
              About
            </HashLink>
          </li>
          <li>
            {" "}
            <Link to="/cars">Car</Link>
          </li>
          {/* <li>
            {" "}
            <Link to="/calculate-your-loan">Calculate your loan</Link>
          
          </li> */}
        </ul>
        <HashLink to="/#downlaod">

        <Button type="button" className="btn btn_primary sm_btn">
          Sign up
        </Button>
        </HashLink>
        <button className="humb_btn" onClick={() => setShow(!show)}>
          {" "}
          <MenuIcon />{" "}
        </button>
      </div>
    </header>
  );
};

export default Header;
