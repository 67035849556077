import React from "react";
type Props={
  aboutus:string
}
const AboutUs = ({aboutus}:Props) => {
  return (
    <section className="about_us_sc al_sc" id="about-us">
      <div className="container fs_48">
        <div className="sec_head text_center">
          <h1>About Us</h1>
         <p dangerouslySetInnerHTML={{__html:aboutus}}/>
        </div>
        <figure className="abt_img">
          <img src="/static/images/about_img.png" alt="img" />
        </figure>
      </div>
    </section>
  );
};

export default AboutUs;
