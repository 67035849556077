import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import Routing from "./Routes";

function App() {
  return <Routing />;
}

export default App;
