import React from "react";
import { Skeleton } from "@mui/material";
type Props = {
  station: ServiceStation | undefined;
  loading: boolean;
};

const CarsSkeleton = () => (
  <div className="crs_lft">
    <Skeleton variant="rectangular" width={"100%"} height={200} />{" "}
    {/* Skeleton for the image */}
    <Skeleton variant="text" width={160} height={30} sx={{marginTop:"100px"}} />{" "}
    {/* Skeleton for the name */}
    <Skeleton variant="text" width={200} height={20} />{" "}
    {/* Skeleton for the country code and phone */}
    <Skeleton variant="text" width={200} height={20} />{" "}
    {/* Skeleton for the address */}
  </div>
);
const CarsLeft = ({ station, loading }: Props) => {
  if(loading){
    return <CarsSkeleton/>
  }
  return (
    <div className="crs_lft">
      <figure>
        <img src={station?.image} alt="img" />
      </figure>
      <h2>{station?.name || "-"}</h2>
      <h3> {(station?.countryCode?.includes("+")
                                ? station?.countryCode
                                : "+" + station?.countryCode || "-") +
                                "-" +
                                (station?.phone || "-")}</h3>
      <h3>{station?.address || ""}</h3>
    </div>
  );
};

export default CarsLeft;
