import MuiPagination from "@mui/material/Pagination";

type Props = {
  page: number;
  className?: string;
  onPageChange: (event: any, newPage: number) => void;
  count: number;
  data: any;
};

const Pagination = ({ page, onPageChange, className, count, data }: Props) => {
  let totalPages = Math.ceil(count / 10);

  return (
    <MuiPagination
      color="primary"
     
      className="Pagination"
      count={totalPages}
      page={page}
      disabled={data?.length === 0}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
     
    />
  );
};

export default Pagination;
