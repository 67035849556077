import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import client from '../../services'

const AboutUs = () => {
    const navigate=useNavigate()
    const [aboutUs,setAboutUs]=useState<string>("")
    const getCms=async()=>{
      try{
        const res=await client.get<CMSApiResponse>("/cms")
        if(res?.status===200){
          const {data}=res?.data
          setAboutUs(data?.privacyPolicy)
        }
      }catch(error:any){
        console.log(error)
      }
    }
    useEffect(()=>{
      getCms()
    },[])
  return (
    <div>
      <div style={{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        background:"black",
        padding:"20px"
      }}>
        <figure onClick={()=>navigate("/")} style={{cursor:"pointer"}}>
          <img className='logo-png' src="/static/images/logo.png" alt=""  />

        </figure>
      </div>
      <h2 style={{textAlign:"center", marginTop:"10px"}}>Privacy Policy</h2>
      <Container maxWidth="lg">
        

      <p dangerouslySetInnerHTML={{__html:aboutUs}}/>
      </Container>
    </div>
  )
}

export default AboutUs
