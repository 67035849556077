import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import { useMediaQuery } from "@mui/system";
import client from "../../services";
type testimonial = {
  id: string;
  image: string;
  title: string;
  description: string;
  name_person: string;
};

const Testimonials = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [test, setTest] = useState<testimonial[]>([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [nav1, setNav1] = useState<any | null>(null);
  const [nav2, setNav2] = useState<any | null>(null);
  const sliderRef1 = useRef<any | null>(null);
  const sliderRef2 = useRef<any | null>(null);

  const handleAfterChange = (index: number) => {
    setActiveIndex(index);
    nav2.slickGoTo(index);
  };
  const getTestimonial = async () => {
    try {
      const res = await client.get<any>("/getTestimonial");
      if (res?.status === 200) {
        const { data } = res?.data;
        console.log(data?.data, "data");

        console.log(data?.data, "hhh");
        const newdata = data?.data?.map((item: any, index: any) => {
          return {
            id: item?._id,
            image: item?.image,
            name_person: item?.user,
            title: item?.title,
            description: item?.description,
          };
        });
        console.log(newdata, "fgfgfg");
        setTest([...newdata]);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
    getTestimonial();
  }, []);
  console.log(test, "test");
  const data = [
    {
      id: 1,
      image: "/static/images/2.png",
      name_person: "Marthe Wallim",
      title: "Tremendous Job Team",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. commodo viverra maecenas",
    },
    {
      id: 2,
      image: "/static/images/3.png",
      name_person: "Marthe Wallim",
      title: "Tremendous Job Team",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. commodo viverra maecenas",
    },
    {
      id: 3,
      image: "/static/images/4.png",
      name_person: "Marthe Wallim",
      title: "Tremendous Job Team",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. commodo viverra maecenas",
    },
    {
      id: 4,
      image: "/static/images/5.png",
      name_person: "Marthe Wallim",
      title: "Tremendous Job Team",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. commodo viverra maecenas",
    },
    {
      id: 5,
      image: "/static/images/6.png",
      name_person: "Marthe Wallim",
      title: "Tremendous Job Team",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. commodo viverra maecenas",
    },
  ];
  console.log(test, "new");
  return (
    <section className="testimonial_sc al_sc ">
      <div className="container">
        <div className="sec_head text_center fs_48">
          <h2>Testimonials</h2>
        </div>

        <Slider
          className="test_img"
          asNavFor={nav1}
          ref={(slider: any) => (sliderRef2.current = slider)}
          slidesToShow={3}
          swipeToSlide={test?.length > 1}
          centerPadding="0px"
          focusOnSelect={test?.length > 1}
          centerMode={true}
          infinite={test?.length > 1}
          slidesToScroll={1}
          arrows={false}
          // autoplay
        >
          {test?.length
            ? [...test, ...test]?.map((item, index) => (
                <div
                  className={`thumb_single ${
                    index === activeIndex ? "active_slide" : ""
                  }`}
                  key={index}
                >
                  <figure>
                    <img
                      src={
                        item?.image || "/images/testimonial_attachment_1.jpg"
                      }
                      alt=""
                    />
                  </figure>
                </div>
              ))
            : undefined}
        </Slider>
        <Slider
          className="test_cnt"
          afterChange={handleAfterChange}
          asNavFor={nav2}
          ref={(slider: any) => (sliderRef1.current = slider)}
          infinite={test?.length > 1}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          dots={true} // Add dots
        >
          {test?.length
            ? [...test, ...test]?.map((item, index) => (
                <div className="info_dv text_center" key={index}>
                  <div className="info_top fs_36">
                    <h2>{item?.title || ""}</h2>
                    <p>{item?.description || ""}</p>
                  </div>
                  <div className="info_btm fs_24">
                    <h6>{item?.name_person || ""}</h6>
                  </div>
                </div>
              ))
            : undefined}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
