import React, { useState } from "react";
import Box from "@mui/material/Box";
import Layout from "../../layout";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
// import { CircleSlider } from "react-circle-slider";
// import ReactCurvedText from 'react-curved-text';
// import ReactDOM from "react-dom";

const MAX = 10.0;
const MIN = 250;
const marks = [
  {
    value: MIN,
    label: "",
  },
  {
    value: MAX,
    label: "",
  },
];

const CalculateLoan = () => {
  const [val, setVal] = React.useState<number>(MIN);
  const [value, changeValue] = useState(20);

  const handleChange = (_: Event, newValue: number | number[]) => {
    setVal(newValue as number);
  };

  const handle = (q: any) => {
    if (q === value) return;
    console.log(q);

    changeValue(q);
  };

  return (
    <Layout>
      <section className="clcultelon_sc">
        <div className="container">
          <h1>Calculate your loan</h1>
          <Box className="clcultelon_mn">
            <Box className="clcultelon_lft">
              <div className="App">
                <div className="textContainer">
                  {/* <div className="minute">MINUTES</div> */}
                </div>
                {/* <CircleSlider
                                    value={value}
                                    stepSize={1}
                                    onChange={(value: any) => handle(value)}
                                    size={250}
                                    
                                    max={100}
                                    gradientColorFrom="#ec008c"
                                    gradientColorTo="#fc6767"
                                    knobRadius={20}
                                    circleWidth={20}
                                    startAtTop={true} 
                                /> */}
                {/* <div className='clcultelon_inr'>
                                    <h3>$200</h3>
                                    <h4>$56.89</h4>
                                        <ReactCurvedText
                                        width={300}
                                        height={300}
                                        cx={150}
                                        cy={150}
                                        rx={100}
                                        ry={100}
                                        startOffset={50}
                                        reversed={false}
                                        text="react-curved-text"
                                        textProps={{ style: { fontSize: 24 } }}
                                        textPathProps={null}
                                        tspanProps={null}
                                        ellipseProps={null}
                                        svgProps={null}
                                    />
                                </div> */}
              </div>
              <figure>
                <img src="/static/images/crcle_sldr.png" alt="img" />
              </figure>
            </Box>
            <Box className="clcultelon_cntr">
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <FormHelperText>Select Car</FormHelperText>

                <Select
                  placeholder="Select  Car"
                  className="slct"
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Age"
                >
                  <MenuItem value={10}>car1</MenuItem>
                  <MenuItem value={20}>car2</MenuItem>
                  <MenuItem value={30}>car3</MenuItem>
                </Select>
              </FormControl>
              <div className="clcultelon_lst">
                <h3>Choose Loan Amount</h3>
                <p>
                  Suspendisse potenti etiam a dui sodales commodo risus nec
                  volutpat libero
                </p>
                <h4>$900.00</h4>
                <Box sx={{ width: 250 }}>
                  <Slider
                    marks={marks}
                    step={10}
                    value={val}
                    valueLabelDisplay="auto"
                    min={MIN}
                    max={MAX}
                    onChange={handleChange}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MIN)}
                      sx={{ cursor: "pointer" }}
                    >
                      ${MIN}
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MAX)}
                      sx={{ cursor: "pointer" }}
                    >
                      ${MAX}
                    </Typography>
                  </Box>
                </Box>
              </div>
              <div className="clcultelon_lst">
                <h3>Choose Down Payment Amount</h3>
                <p>
                  Suspendisse potenti etiam a dui sodales commodo risus nec
                  volutpat libero
                </p>
                <h4>$900.00</h4>
                <Box sx={{ width: 250 }}>
                  <Slider
                    marks={marks}
                    step={10}
                    value={val}
                    valueLabelDisplay="auto"
                    min={MIN}
                    max={MAX}
                    onChange={handleChange}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MIN)}
                      sx={{ cursor: "pointer" }}
                    >
                      ${MIN}
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MAX)}
                      sx={{ cursor: "pointer" }}
                    >
                      ${MAX}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Box>
            <Box className="clcultelon_ryt">
              <div className="clcultelon_lst">
                <h3>Choose Down Payment Amount</h3>
                <p>
                  Suspendisse potenti etiam a dui sodales commodo risus nec
                  volutpat libero
                </p>
                <h4>$900.00</h4>
                <Box sx={{ width: 250 }}>
                  <Slider
                    marks={marks}
                    step={10}
                    value={val}
                    valueLabelDisplay="auto"
                    min={MIN}
                    max={MAX}
                    onChange={handleChange}
                  />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MIN)}
                      sx={{ cursor: "pointer" }}
                    >
                      ${MIN}
                    </Typography>
                    <Typography
                      variant="body2"
                      onClick={() => setVal(MAX)}
                      sx={{ cursor: "pointer" }}
                    >
                      ${MAX}
                    </Typography>
                  </Box>
                </Box>
              </div>
              <div className="emi_bx">
                <div className="emi_inr">
                  <h2>$</h2>
                </div>
                <p>Your EMI eligbility per month is</p>
                <h3>$4,500.00</h3>
              </div>
            </Box>
          </Box>
        </div>
      </section>
    </Layout>
  );
};

export default CalculateLoan;
