import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useEffect, useState } from "react";
import client from "../../services";

interface QAItem {
  question: string;
  answer: string;
  isDeleted: boolean;
  _id: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

type QAList = QAItem[];

const Faqs = () => {
  const [expanded, setExpanded] = useState("");
  const [faq,setFaq]=useState<QAItem[]>([])

  const handleAccordionChange = (itemId: string) => {

      setExpanded(expanded === itemId ? "" : itemId);
    
  };

  const getCms=async()=>{
    try{
      const res=await client.get("/faq")
      if(res?.status===200){
        const {data}=res?.data
        console.log(data,"dataaaa")
        const faqs:QAList=data?.data?.map((item:QAItem,index:number)=>{
          return {
            id:item?._id,
            question:item?.question,
            answer:item?.answer
          }
        })
        setFaq(faqs)
        // setAboutUs(data?.about_us)
      }
    }catch(error:any){
      console.log(error)
    }
  }
  useEffect(()=>{
    getCms()
  },[])

  const data = [
    {
      id: 1,
      question: "What is QFI mobile app?",
      answer:
        "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,content here', making it look like readable English. Many desktop  publishing packages and web page editors now use Lorem Ipsum as  their default model text, and a search for 'lorem ipsum' will  uncover many web sites still in their infancy",
    },
    {
      id: 2,
      question: "What is QFI mobile app?",
      answer:
        "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,content here', making it look like readable English. Many desktop  publishing packages and web page editors now use Lorem Ipsum as  their default model text, and a search for 'lorem ipsum' will  uncover many web sites still in their infancy",
    },
    {
      id: 3,
      question: "What is QFI mobile app?",
      answer:
        "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here,content here', making it look like readable English. Many desktop  publishing packages and web page editors now use Lorem Ipsum as  their default model text, and a search for 'lorem ipsum' will  uncover many web sites still in their infancy",
    },
  ];
  const style = {
    borderRadius: "14px",
    boxShadow: "1px 1px 5px 4px #0000001A",
  };
  const border = {
    borderTop: "1px solid #DEDEDE",
  };
  return (
    <section className="mn_faqs " id="/faq">
      <div className="container">
        <div className="sec_head text_center fs_48">
          <h1>FAQ's</h1>
        </div>

        {faq?.length
          ? faq?.map((item) => {
              return (
                <Accordion
                  key={item?.id}
                  sx={style}
                  expanded={expanded === item.id.toString()}
                  // expanded={expanded}
                  onChange={() => handleAccordionChange(item.id.toString())}
                >
                  <AccordionSummary
                   expandIcon={
                    expanded === item.id.toString() ? (
                      <figure>
                        {" "}
                        <img src="/static/images/minus.svg" alt="" />
                      </figure>
                    ) : (
                      <figure>
                        <img src="/static/images/plus.svg" alt="" />
                      </figure>
                    )
                  }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p>{item?.question || ""}</p>
                  </AccordionSummary>
                  <AccordionDetails sx={border}>
                    <Typography>{item?.answer || ""}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })
          : null}
      </div>
    </section>
  );
};

export default Faqs;
