import "./Home.scss";

import Layout from "../../layout";
import Introduction from "../../features/landingPage/Introduction";
import AboutUs from "../../features/landingPage/AboutUs";
import CalculateLoan from "../../features/landingPage/CalculateLoan";
import DownloadApp from "../../features/landingPage/DownloadApp";
import Faqs from "../../features/landingPage/Faqs";
import Testimonials from "../../features/landingPage/Testimonials";
import client from "../../services";
import { useEffect, useState } from "react";

const HomePage = () => {
  const [aboutUs,setAboutUs]=useState<string>("")
  const getCms=async()=>{
    try{
      const res=await client.get<CMSApiResponse>("/cms")
      if(res?.status===200){
        const {data}=res?.data
        setAboutUs(data?.privacyPolicy)
      }
    }catch(error:any){
      console.log(error)
    }
  }
  useEffect(()=>{
    getCms()
  },[])
  return (
    <Layout>
      <div className="wrapCls">
        <Introduction />
        <AboutUs aboutus={aboutUs} />
        <CalculateLoan />
        <Testimonials />
        <DownloadApp />
        <Faqs />
      </div>
    </Layout>
  );
};

export default HomePage;
