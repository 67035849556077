import { Route, Routes } from "react-router-dom";
import Pages from "./pages";

const Routing = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Pages.HomePage />} />
        <Route path="/cars" element={<Pages.ManageCars />} />
        <Route path="/calculate-your-loan" element={<Pages.CalculateLoan />} />
        <Route path="/privacy-policy" element={<Pages.AboutUs/>}/>
      </Routes>
    </div>
  );
};
export default Routing;
