import { Button } from "@mui/material";
import React from "react";
import {HashLink} from "react-router-hash-link"
const Introduction = () => {
  return (
    <section className="intro_sec">
      <div className="container ">
        <div className=" banner_head fs_56">
        <h1>Finance your Car</h1>
        <p>
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p>
        <HashLink to="/#downlaod">
        <Button className="btn btn_black" type="button" >
          Get Started
        </Button>
        </HashLink>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
