import { Skeleton } from "@mui/material";
import React from "react";
type Props={
  vehicle:Vehicle|undefined;
  loading:boolean
}
const PriceFormatter = (number: number) => {
  const formatter = new Intl.NumberFormat("en-US");
  const formattedNumber = formatter.format(number);
  return formattedNumber;
};
const CarsSkeleton = () => (
  <div className="crs_lft">
    <Skeleton variant="rectangular" width={"100%"} height={200} />{" "}
    {/* Skeleton for the image */}
    <Skeleton variant="text" width={160} height={30} sx={{marginTop:"100px"}} />{" "}
    {/* Skeleton for the name */}
    <Skeleton variant="text" width={200} height={20} />{" "}
    {/* Skeleton for the country code and phone */}
    <Skeleton variant="text" width={200} height={20} />{" "}
    {/* Skeleton for the address */}
  </div>
);
const CarsRight = ({vehicle,loading}:Props) => {
if(loading){
  return <CarsSkeleton/>
}
  return (
    <div className="crs_lft crs_lt">
        <figure >
            <img src={vehicle?.image[0]} alt="img"  />
        </figure>
        <h2>{vehicle?.name || "-"} <br/> ({vehicle?.categoryName})</h2>
        <h3>${PriceFormatter(vehicle?.price || 0)}</h3>
    </div>
    
  );
};

export default CarsRight;
